import React, {useState, useEffect} from "react";
import {useLocation} from "react-router";
import queryString from "query-string";
import PaymentDone from "./PaymentDone/PaymentDone";
import {setFinishOrder} from "../../../api/api";
import Preloader from "../../Common/Preloader/Preloader";

const FinishOrder = ({ paymentType }) => {
    const [invoice, setInvoice] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const params = useLocation();
    const urlString = params.search;
    const hash = queryString.parse(urlString);

    useEffect(() => {
        if (urlString && hash?.invoice_id) {
            setFinishOrder(hash?.invoice_id, paymentType).then((resp) => {
                if (resp.id) {
                    setInvoice(resp);
                } else if (resp.errors) {
                    setInvoice(resp.errors);
                }
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setIsLoading(false);
            })
        }
    }, [])

    return (
        <div>
            {isLoading ? (
                <Preloader/>
            ) : (
                <PaymentDone
                    orderUrl={invoice?.payment?.free_access_order_url}
                    success={invoice && !invoice?.invoice_status}
                    receiptUrl={invoice?.receipt_url}
                />
            )}
        </div>
    )
}

export default FinishOrder;