import { useEffect } from 'react';
import styles from './TotalPrice.module.scss';

const TotalPrice = ({ price = 0, discountType, discountValue = 0, setFinalPrice = () => {} }) => {
    const totalPrice = discountType === 'PERC'
        ? Math.ceil(price - ((price * discountValue) / 100))
        : price - discountValue;

    useEffect(() => {
        if (totalPrice) {
            setFinalPrice(totalPrice);
        }
    }, [totalPrice])

    return (
        <div className={styles.totalPrice}>
            {price > 0 && discountValue > 0 && (
                <p className={`${styles.oldPriceValue} ${styles.price}`}>{price} грн</p>
            )}
            <div className={styles.priceInfo}>
                <p className={styles.priceText}>Ціна замовлення:</p>
                <span className={`${styles.totalPriceValue} ${styles.price}`}>{totalPrice} грн</span>
            </div>
        </div>
    )
}

export default TotalPrice;