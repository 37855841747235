import React, {useState} from "react";
import styles from './FabricsContent.module.scss';
import {CopyToClipboard} from "react-copy-to-clipboard";
import copyIcon from "../../../../assets/icons/copy.svg";
import {getBaseUrl} from "../../../../store/selectors";

const FabricsContent = ({fabricsAll, isCalculator = null, totalPages, setFabric = null, closeFabricPopup = null}) => {
    const baseUrl = getBaseUrl;
    const [selectedFabricId, setSelectedFabricId] = useState(null);

    const onFabricSelect = (el) => {
        setFabric(el);
        setSelectedFabricId(el.id);
        setTimeout(() => {
            closeFabricPopup();
        }, 200);
    }

    return (
        <ul className={isCalculator ? `${styles.fabricsContent} ${styles.fabricsContentPopup}` : styles.fabricsContent}
            style={isCalculator && totalPages <= 1 ? {height: 'calc(100vh - 190px)'} : isCalculator && totalPages > 1 ? {height: 'calc(100vh - 235px)'} : null}>
            {fabricsAll && fabricsAll.length > 0 && (
                fabricsAll.map((item, index) => (
                    <li className={item.id === selectedFabricId ? `${styles.chosenFabric} ${styles.fabric}` : styles.fabric}
                        key={index} onClick={isCalculator ? () => {
                        onFabricSelect(item)
                    } : null}>
                        <div className={styles.fabric__left}>
                            <div className={styles.fabric__image}>
                                <img src={item.images[0]} alt=""/>
                            </div>
                            <div className={styles.fabric__info}>
                                <h5>{item.type?.type || 'Невідомий тип тканини'}</h5>
                                <p>{item.name}</p>
                                <span className={styles.fabric__remainder}>Залишок <span
                                    className={item.fabric_a?.quantity <= 10 ? styles.red : null}><b>A</b>{`(${item.fabric_a?.quantity}м)`}</span>,
                                    {item.fabric_b && (
                                        <span
                                            className={item.fabric_b?.quantity <= 10 ? styles.red : null}><b>B</b>{`(${item.fabric_b?.quantity}м)`}</span>
                                    )}
                                    </span>
                            </div>
                        </div>
                        {isCalculator
                            ? <div className={styles.fabric__rightCalculator}>
                                <div
                                    className={item?.mono_fabric_type.includes('A') ? styles.blockAActive : styles.blockA}>
                                    <p>
                                        <span>A</span>
                                    </p>
                                </div>
                                <div
                                    className={item?.mono_fabric_type.includes('B') ? styles.blockBActive : styles.blockB}>
                                    <p>
                                        <span>B</span>
                                    </p>
                                </div>
                            </div>
                            : <div className={styles.fabric__right}>
                                <div className={styles.fabric__category}>
                                    {item.mono_fabric_type && (
                                        item.mono_fabric_type.map((element, index) => (
                                            <span key={index}>{element}</span>
                                        ))
                                    )}
                                </div>
                                <CopyToClipboard text={`${baseUrl}/product/?id=${item.id}`} className="click-effect">
                                    <button>
                                        <img src={copyIcon} alt=""/>
                                    </button>
                                </CopyToClipboard>
                            </div>
                        }

                    </li>
                ))
            )}
        </ul>
    )
}

export default FabricsContent;