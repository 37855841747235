import {Route, Routes, useLocation} from "react-router-dom";
import Auth from "./Auth/Auth";
import UserMenu from "../Navigations/UserMenu";
import React, { useRef } from "react";
import Contacts from "./Contacts/Contacts";
import Callback from "./Callback/Callback";
import Status from "./Status/Status";
import DetailsPayment from "./DetailsPayment/DetailsPayment";
import Requisites from "./Requisites/Requisites";
import FinishOrder from "./FinishOrder/FinishOrder";
import Fabric from "../UserPage/Fabric";
import queryString from "query-string";

const ClientPage = () => {
    const { pathname, search } = useLocation();
    const hash = queryString.parse(search);
    const orderHash = useRef(hash?.order);

    return (
        <>
            <Routes>
                <Route path={'/*'} element={<Auth/>}/>
                <Route path={'/orders/*'} element={<Status orderHash={orderHash.current} />} />
                <Route path={'/contacts'} element={<Contacts />} />
                <Route path={'/details_payment'} element={<DetailsPayment />} />
                <Route path={'/callback'} element={<Callback />} />
                <Route path={'/requisites/:id'} element={<Requisites/>} />

                <Route path={'/product/*'} element={<Fabric/>} />
                <Route path={'/monobank/finish-order/*'} element={<FinishOrder paymentType='monobank' />} />
                <Route path={'/wayforpay/finish-order/*'} element={<FinishOrder paymentType='wayforpay' />} />
            </Routes>
            {!pathname.startsWith('/product/') && <UserMenu/>}
        </>
    )
}

export default ClientPage;