import React, { useEffect, useState } from "react";
import styles from './DeliveryNP.module.scss';
import { useFormik } from "formik";
import './delivery.scss'
import NovaPoshtaCities from "./NovaPoshtaCities/NovaPoshtaCities";
import NovaPoshtaDepartments from "./NovaPoshtaDepartments/NovaPoshtaDepartments";
import NovaPoshtaWeight from "./NovaPoshtaWeight/NovaPoshtaWeight";
import DeliveryIPay from "./DeliveryIPay/DeliveryIPay";
import copyIcon from "../../../assets/icons/copy.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { deleteDeliveryNPTTN, getAccessToken, setDeliverNP, setDeliverNPTTN } from "../../../api/api";
import Parser from "../../Common/Parser/Parser";
import InputMask from "react-input-mask";

const DeliveryNP = ({ deliveryOrder, lastOrderId }) => {
    const [cityValue, setCityValue] = useState(deliveryOrder?.receiver?.delivery_receiver?.city || '');
    const [saveData, setSaveData] = useState('');
    const [requiredNPValues, setRequiredNPValues] = useState(null);
    const [oldRequiredNPValues, setOldRequiredNPValues] = useState(null);
    const [errors, setErrors] = useState({});
    const isAllRequiredValuesFilled = requiredNPValues?.first_name && requiredNPValues?.last_name && requiredNPValues?.phone && requiredNPValues?.city && requiredNPValues?.address && requiredNPValues?.specified_weight && requiredNPValues?.estimate_order_price;

    const getRequiredNPValues = (updatedDeliveryOrder) => {
        if (!updatedDeliveryOrder) return null;
        return {
            id: updatedDeliveryOrder?.id,
            first_name: updatedDeliveryOrder?.receiver?.delivery_receiver?.first_name || '',
            last_name: updatedDeliveryOrder?.receiver?.delivery_receiver?.last_name || '',
            phone: updatedDeliveryOrder?.receiver?.delivery_receiver?.phone || '',
            city: updatedDeliveryOrder?.receiver?.delivery_receiver?.city || '',
            city_ref: updatedDeliveryOrder?.receiver?.city_identifier || '',
            address: updatedDeliveryOrder?.receiver?.address || '',
            address_ref: updatedDeliveryOrder?.receiver?.address_identifier || '',
            max_weight_allowed: updatedDeliveryOrder?.receiver?.max_weight_allowed || '',
            warehouse_number: updatedDeliveryOrder?.receiver?.department_number || '',
            specified_weight: updatedDeliveryOrder?.specified_weight || '',
            payer: updatedDeliveryOrder?.delivery?.payer || '',
            estimate_order_price: updatedDeliveryOrder?.delivery?.estimate_order_price || '',
        }
    }

    useEffect(() => {
        if (deliveryOrder) {
            const requiredNPValues = getRequiredNPValues(deliveryOrder);
            setRequiredNPValues(requiredNPValues);
            setOldRequiredNPValues(requiredNPValues);
        }
    }, [deliveryOrder])

    const auditDates = (value, fieldName = null) => {
        if (saveData !== value) {
            getAccessToken().then((token) => {
                if (fieldName) {
                    let processedValue = fieldName === 'phone' ? value ? value.replace(/[ ()-]/g, '') : null : value;
                    setDeliverNP(token, lastOrderId, { [fieldName]: processedValue }).then((res) => {
                        if (res.errors) {
                            setErrors(prevErrors => ({ ...prevErrors, ...res.errors }));
                        } else {
                            setRequiredNPValues(getRequiredNPValues(res));
                            setErrors(prevErrors => {
                                const { [fieldName]: _, ...restErrors } = prevErrors;
                                return restErrors;
                            });
                        }
                    })
                } else {
                    setDeliverNP(token, lastOrderId, value).then((res) => {
                        if (res.errors) {
                            setErrors(prevErrors => ({ ...prevErrors, ...res.errors }));
                        } else {
                            setRequiredNPValues(getRequiredNPValues(res));
                            const restErrors = { ...errors };
                            Object.keys(value).forEach(key => {
                                if (key in restErrors) {
                                    delete restErrors[key];
                                }
                            })
                            setErrors(restErrors);
                        }
                    })
                }
            });
        }
    }

    const formValues = {
        first_name: deliveryOrder?.receiver?.delivery_receiver?.first_name || '',
        last_name: deliveryOrder?.receiver?.delivery_receiver?.last_name || '',
        phone: deliveryOrder?.receiver?.delivery_receiver?.phone || '',
        declared_order_price: deliveryOrder?.delivery?.estimate_order_price || '',
        declared_delivery_price: deliveryOrder?.delivery?.estimate_delivery_price || '',
        ttn: deliveryOrder?.delivery?.ttn || '',
        comment: deliveryOrder?.delivery?.comment || '',
    }
    const { isSubmitting, setSubmitting, handleSubmit, handleChange, values, setValues, setFieldValue } = useFormik({
        initialValues: formValues,

        onSubmit: (e) => {
            getTTN();
        },
    });

    const handleSetValues = (newValues) => {
        const { city, ...newFormValues } = newValues;
        setValues(prevValues => ({
            ...prevValues,
            ...newFormValues
        }));
        setCityValue(prevCity => city !== undefined ? city : prevCity);
    }

    const handleValidChange = (key, value) => {
        if (/[^a-zа-яґєії+\-' ]+/ig.test(value) && value !== '') {
            return;
        }
        setFieldValue(key, value);
    }

    const validateRequiredNPValues = () => {
        const newErrors = {};

        for (const [key, value] of Object.entries(requiredNPValues)) {
            if (!value) {
                newErrors[key] = 'This field may not be blank.';
            }
        }

        if (Object.keys(errors).length > 0 || Object.keys(newErrors).length > 0) {
            setErrors((prevErrors) => ({ ...prevErrors, ...newErrors}));
            return false;
        }

        return true;
    }

    const getTTN = () => {
        if (!deliveryOrder) return;
        const isValid = validateRequiredNPValues();
        if (!isValid) {
            setSubmitting(false);
            return;
        };

        getAccessToken().then(async (token) => {
            if (values.ttn) {
                try {
                    const deleteResponse = await deleteDeliveryNPTTN(token, requiredNPValues.id);
                    if (!deleteResponse.id) {
                        console.log(deleteResponse);
                        setSubmitting(false);
                        return;
                    }
                    setRequiredNPValues(prevValues => ({
                        ...prevValues,
                        estimate_order_price: ''
                    }));
                    setValues(prevValues => ({
                        ...prevValues,
                        declared_delivery_price: '',
                        ttn: ''
                    }));
                } catch (error) {
                    console.log(error);
                    setSubmitting(false);
                }
            }
            setDeliverNPTTN(token, requiredNPValues.id).then((response) => {
                if (response?.id && response?.delivery?.ttn) {
                    const newValues = {
                        ttn: response.delivery.ttn,
                        declared_delivery_price: response.delivery.estimate_delivery_price
                    };
                    setValues((prevValues) => ({
                        ...prevValues,
                        ...newValues
                    }));
                    setRequiredNPValues((prevValues) => ({
                        ...prevValues,
                        estimate_order_price: response.delivery.estimate_order_price
                    }));
                    setOldRequiredNPValues({
                        ...requiredNPValues,
                        estimate_order_price: response.delivery.estimate_order_price
                    });
                } else if (response.errors) {
                    console.log(response?.errors?.ttn);
                }
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setSubmitting(false);
            });
        });
    }

    function areObjectsEqual(obj1, obj2) {
        const obj1Keys = Object.keys(obj1);
        const obj2Keys = Object.keys(obj2);
    
        if (obj1Keys.length !== obj2Keys.length) {
            return false;
        }
    
        return obj1Keys.every((key) => obj1[key] === obj2[key]);
    }

    return (
        <div className={`${styles.deliveryNP} deliveryNP`}>
            <form onSubmit={handleSubmit}>
                <div className={styles.deliveryNP__content}>
                    <div className={styles.deliveryNP__row}>
                        <div className="baseInputContainer">
                            <span className="coloredLabel colored--first_name"></span>
                            <input
                                placeholder={'Ім`я Отримувача'}
                                className={`baseInput ${errors.first_name ? 'baseInputError' : ''}`}
                                id="first_name"
                                name="first_name"
                                type="text"
                                onChange={(e) => handleValidChange(e.target.name, e.target.value)}
                                value={values.first_name}
                                onFocus={(e) => setSaveData(e.target.value)}
                                onBlur={(e) => auditDates(e.target.value, 'first_name')}
                            />
                        </div>
                        <div className="baseInputContainer">
                            <span className="coloredLabel colored--last_name"></span>
                            <input
                                placeholder={'Прізвище Отримувача'}
                                className={`baseInput ${errors.last_name ? 'baseInputError' : ''}`}
                                id="last_name"
                                name="last_name"
                                type="text"
                                onChange={(e) => handleValidChange(e.target.name, e.target.value)}
                                value={values.last_name}
                                onFocus={(e) => setSaveData(e.target.value)}
                                onBlur={(e) => auditDates(e.target.value, 'last_name')}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="baseInputContainer">
                            <span className="coloredLabel colored--phone"></span>
                            <InputMask
                                mask="+38 (099) 999-99-99"
                                value={values.phone}
                                onChange={handleChange}
                                onFocus={(e) => setSaveData(e.target.value)}
                                onBlur={(e) => auditDates(e.target.value, 'phone')}
                            >
                                {(inputProps) => (
                                    <input
                                        {...inputProps}
                                        placeholder={'Телефон Отримувача'}
                                        className={`baseInput ${errors.phone ? 'baseInputError' : ''}`}
                                        aria-label={"phone"}
                                        id="phone"
                                        name="phone"
                                    />
                                )}
                            </InputMask>
                        </div>
                    </div>
                    <div className={styles.deliveryNP__row}>
                        <NovaPoshtaCities
                            cityValue={cityValue}
                            setCityValue={setCityValue}
                            updateData={auditDates}
                            error={errors.city}
                        />
                        <NovaPoshtaDepartments
                            cityId={requiredNPValues?.city_ref}
                            department={requiredNPValues?.address || ''}
                            updateData={auditDates}
                            error={errors.address}
                        />
                    </div>
                    <NovaPoshtaWeight
                        weight={requiredNPValues?.specified_weight || ''}
                        setWeight={(specified_weight) => setRequiredNPValues(prevValues => ({ ...prevValues, specified_weight }))}
                        auditDates={auditDates}
                        error={errors.specified_weight}
                    />
                    <DeliveryIPay isIPay={requiredNPValues?.payer === 'Sender'} auditDates={auditDates}/>
                    <div>
                        <input
                            placeholder={'Оголошена вартість'}
                            className={`baseInput ${errors.estimate_order_price ? 'baseInputError' : ''}`}
                            id="declared_order_price"
                            name="declared_order_price"
                            type="number"
                            onChange={handleChange}
                            value={values.declared_order_price}
                            onFocus={(e) => setSaveData(e.target.value)}
                            onBlur={(e) => auditDates(e.target.value, 'estimate_order_price')}
                        />
                    </div>
                    <div className={styles.closedInput}>
                        <input
                            placeholder={'Орієнтовна Вартість Доставки'}
                            className={'baseInput'}
                            id="declared_delivery_price"
                            name="declared_delivery_price"
                            type="number"
                            value={values.declared_delivery_price}
                            readOnly
                        />
                    </div>
                    <div className={styles.transportNumber}>
                        <input
                            placeholder={'ТТН'}
                            className={'baseInput'}
                            id="ttn"
                            name="ttn"
                            type="number"
                            value={values.ttn}
                            readOnly
                        />
                        <CopyToClipboard text={values.ttn} className={`${styles.copyButton} click-effect`}>
                            <button type='button' tabIndex={0}>
                                <img src={copyIcon} alt="" />
                            </button>
                        </CopyToClipboard>
                    </div>
                    <div>
                        <textarea
                            cols="30"
                            rows="10"
                            placeholder={'Коментар'}
                            id="comment"
                            name="comment"
                            onChange={handleChange}
                            value={values.comment}
                            onFocus={(e) => setSaveData(e.target.value)}
                            onBlur={(e) => auditDates(e.target.value || null, 'comment')}
                        />
                    </div>
                    <div className={styles.action}>
                        {values.ttn ? (
                            <button
                                className={'globalBtn'}
                                type='submit'
                                disabled={!isAllRequiredValuesFilled
                                    || isSubmitting
                                    || areObjectsEqual(requiredNPValues, oldRequiredNPValues)
                                }
                            >
                                <span>Оновити ТТН</span>
                            </button>
                        ): (
                            <button className={'globalBtn'} type='submit' disabled={!isAllRequiredValuesFilled || isSubmitting}>
                                <span>Створити ТТН</span>
                            </button>
                        )}
                    </div>
                </div>
            </form>
            <Parser
                values={{
                    first_name: values.first_name,
                    last_name: values.last_name,
                    phone: values.phone,
                    city: cityValue,
                }}
                setValues={handleSetValues}
                updateData={auditDates}
                parserStyles={{ backgroundColor: '#F0B5B7' }}
            />
        </div>
    )
}

export default DeliveryNP;