import React from "react";
import ProductItem from "../../../Common/ProductItem/ProductItem";
import { getAccessToken, removeProduct, updatePayment } from "../../../../api/api";

const CartProducts = ({items, setItems, setOrderPayment, isDisabled}) => {
    const handleDeleteItem = (id) => {
        getAccessToken().then((token) => {
            removeProduct(token, id).then(result => {
                if (result === 204) {
                    const newItems = items.filter(item => item.id !== id);
                    setItems(newItems);
                    if (newItems.length === 0) {
                        updatePayment(token, items[0].order, { final_amount: null, amount: null, status: 'NONE' }).then((res) => {
                            if (res.id) {
                                setOrderPayment(res);
                            }
                        })
                    }
                }
            })
        })
    }

    return (
        <div>
            {items && items.length > 0 && items.map((el, index) => (
                <ProductItem item={el} key={index} onDelete={handleDeleteItem} isDisabled={isDisabled}/>
            ))}
        </div>
    )
}

export default CartProducts;