export const baseUrl = 'https://panel.egodevelopment.pp.ua/api/v1/';

export const setTokens = (access, refresh) => {
    try {
        localStorage.setItem('accessEgoToken', access);
        localStorage.setItem('refreshEgoToken', refresh);
    } catch (e) {
        console.log(e);
    }
}

export const logout = async () => {
    try {
        localStorage.removeItem('accessEgoToken');
        localStorage.removeItem('refreshEgoToken');
        return 'ok';
    } catch (e) {
        console.log(e);
    }
}

export const setNewAccessToken = async (token) => {
    try {
        localStorage.setItem('accessEgoToken', token)
        return 'ok';
    } catch (e) {
        console.log(e);
    }
}

export const getAccessToken = async () => {
    try {
        return await localStorage.getItem('accessEgoToken');
    } catch (e) {
        console.log(e);
    }
};

export const refreshAccessToken = async () => {
    try {
        const refreshToken = localStorage.getItem('refreshEgoToken');
        if (refreshToken) {
            const response = await fetch(`${baseUrl}users/token/refresh/`, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    refresh: refreshToken,
                })
            });
            const newAccessToken = response.json();

            newAccessToken.then((res) => {
                setNewAccessToken(res.access).then((res) => {
                    if (res) {
                        return newAccessToken;
                    }
                })
            })
            return newAccessToken;

        }
    } catch (error) {
        if (error) {
            logout().then(() => console.log('logout'));
        }
    }
}

export const login = async (name, password) => {
    try {
        const response = await fetch(`${baseUrl}users/token/`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: name,
                password: password,
            })
        });
        const result = response.json();
        result.then((res) => {
            if (res && res.access && res.refresh) {
                setTokens(res.access, res.refresh);
            } else {
                return result;
            }
        })
        return result;
    } catch (error) {
        console.log(error);
    }
}

export const getUserInfo = async (token) => {
    try {
        const response = await fetch(`${baseUrl}users/`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return getUserInfo(res.access).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const getStorageProducts = async (token, page) => {
    try {
        const response = await fetch( `${baseUrl}products/warehouse-items/?page_size=10&page=${page}` , {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return getStorageProducts(res.access).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const getProductDetail = async (id) => {
    try {
        const response = await fetch( `${baseUrl}products/warehouse-items/${id}/` , {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return getStorageProducts(res.access).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const getFabrics = async (token, page, filter = null, search = null) => {
    try {
        const response = await fetch( `${baseUrl}products/fabric/?page=${page}${filter ? `&type=${filter}` : ''}${search ? `&search=${search}` : ''}` , {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return getFabrics(res.access).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const getFabricItem = async (id) => {
    try {
        const response = await fetch( `${baseUrl}products/fabric/${id}/` , {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        return response.json();

    } catch (error) {
        console.log(error);
    }
}

export const getOrders = async (token, page, id = null, isFavourite = null) => {
    try {
        const response = await fetch( id && isFavourite ? `${baseUrl}orders/?page=${page}&manager=${id}&is_favourite=${isFavourite}`
            : id && !isFavourite ? `${baseUrl}orders/?page=${page}&manager=${id}`
                : `${baseUrl}orders/?page=${page}` , {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return getOrders(res.access, id, isFavourite).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const sendOrderHash = async (hash) => {
    try {
        const response = await fetch( `${baseUrl}orders/free-access/?order_hash=${hash}` , {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        return response.json();

    } catch (error) {
        console.log(error);
    }
}

export const getCartOrder = async (token, id) => {
    try {
        const response = await fetch( `${baseUrl}products/order-product/?order=${id}`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return getCartOrder(res.access, id).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const setOrderDuplicate = async (token, id) => {
    try {
        const response = await fetch( `${baseUrl}orders/duplicate/` , {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                order_id: id,
            })
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return setOrderDuplicate(res.access, id).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const getOrdersRecentViews = async (token, value) => {
    try {
        const response = await fetch( `${baseUrl}orders/recent-views/?manager=${value}` , {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return getOrdersRecentViews(res.access, value).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}


export const getDeliverNP = async (token, value) => {
    try {
        const response = await fetch( `${baseUrl}delivery/nova_poshta/?order_id=${value}` , {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return getDeliverNP(res.access, value).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const setDeliverNP = async (token, id, value = null) => {
    console.log(value)
    try {
        const response = await fetch( `${baseUrl}delivery/nova_poshta/` , {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                order_id: id,
                ...value,
            })
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return setDeliverNP(res.access, value).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const setAddProduct = async (token, orderId, item) => {

    try {
        const response = await fetch( `${baseUrl}products/order-product/` , {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                order: orderId,
                warehouse_item: item,
            })
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return setAddProduct(res.access, orderId, item).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const setDeliverNPTTN = async (token, id) => {
    try {
        const response = await fetch( `${baseUrl}delivery/nova_poshta/${id}/waybill/` , {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return setDeliverNPTTN(res.access, id).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const deleteDeliveryNPTTN = async (token, id) => {
    try {
        const response = await fetch( `${baseUrl}delivery/nova_poshta/${id}/` , {
            method: "DELETE",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return deleteDeliveryNPTTN(res.access, id).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const getCities = async (token, value) => {
    try {
        const response = await fetch( `${baseUrl}delivery/nova_poshta/location/city/?city=${value}` , {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return getCities(res.access, value).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const getDepartment = async (token, value, searchValue = '') => {
    try {
        const response = await fetch( `${baseUrl}delivery/nova_poshta/location/warehouse/?city_ref=${value}&find_by_string=${searchValue}` , {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return getDepartment(res.access, value).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const setNewOrder = async (token, managerId) => {
    try {
        const response = await fetch(`${baseUrl}orders/` , {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                manager: managerId,
            })
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return setNewOrder(res.access, managerId).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const getOrderInfo = async (token, id) => {
    try {
        const response = await fetch( `${baseUrl}orders/${id}/` , {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return getOrderInfo(res.access, id).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const updateCustomer = async (token, orderId, value) => {
    try {
        const response = await fetch( `${baseUrl}customers/` , {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                order_id: orderId,
                ...value,
            })
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return updateCustomer(res.access, orderId, value).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const updatePayment = async (token, orderId, value) => {
    try {
        const response = await fetch( `${baseUrl}payments/` , {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                order_id: orderId,
                ...value,
            })
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return updatePayment(res.access, orderId, value).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const updateOrder = async (token, orderId, managerId, value) => {
    try {
        const response = await fetch( `${baseUrl}orders/` , {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: orderId,
                manager: managerId,
                ...value,
            })
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return updateOrder(res.access, value).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const getUserInfoPhone = async (orderId, number) => {
    try {
        const response = await fetch( `${baseUrl}orders/free-access-customer/` , {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                order_id: orderId,
                phone_last_digits: number,
            })
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return getUserInfoPhone(res.access, orderId, number).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const sendPayMonobank = async (id) => {
    try {
        const response = await fetch( `${baseUrl}payments_types/monobank/` , {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                payment_id: id,
            })
        });
        return response.json();

    } catch (error) {
        console.log(error);
    }
}

export const sendPayWayForPay = async (id) => {
    try {
        const response = await fetch( `${baseUrl}payments_types/wayforpay/` , {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                payment_id: id,
            })
        });
        return response.json();

    } catch (error) {
        console.log(error);
    }
}

export const setFinishOrder = async (id, paymentType) => {
    try {
        const response = await fetch( `${baseUrl}payments_types/${paymentType}/${id}/successful/` , {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                payment_id: id,
            })
        });
        return response.json();

    } catch (error) {
        console.log(error);
    }
}

export const removeOrder = async (token, id) => {
    try {
        const response = await fetch( `${baseUrl}orders/${id}/` , {
            method: "DELETE",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });

        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return removeOrder(res.access, id).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.status;
        }

    } catch (error) {
        console.log(error);
    }
}

export const getSearchResult = async (token, value, is_order= true, is_fabric = true, is_warehouse_item = true, page) => {
    try {
        const response = await fetch( `${baseUrl}search/?query=${value}&is_order=${is_order}&page=${page}&is_fabric=${is_fabric}&is_warehouse_item=${is_warehouse_item}` , {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return getSearchResult(res.access, value).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}


        // GENERAL

export const getWorkSchedule = async () => {
    try {
        const response = await fetch( `${baseUrl}general/work_schedule/` , {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return getWorkSchedule(res.access).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const getPaymentDetail = async (orderId) => {
    try {
        const response = await fetch( `${baseUrl}general/payment_details/?order=${orderId}` , {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });

        return response.json();
    } catch (error) {
        console.log(error);
    }
}

export const getCompanyInfo = async () => {
    try {
        const response = await fetch( `${baseUrl}general/company/` , {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return getCompanyInfo(res.access).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const getReason = async () => {
    try {
        const response = await fetch( `${baseUrl}general/call_order/reason/` , {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return getReason(res.access).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const setCallOrder = async (name, phone, reason) => {
    try {
        const response = await fetch( `${baseUrl}general/call_order/` , {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: name,
                phone: phone,
                reason: reason,
            })
        });
        return response.json();
    } catch (error) {
        console.log(error);
    }
}

export const createProduct = async (token, id = null) => {
    try {
        const response = await fetch(id ? `${baseUrl}calculator/kit/templates/size/?order_product_id=${id}` : `${baseUrl}calculator/kit/templates/size/`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return createProduct(res.access, id).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const getFabricTemplate = async (token, fabricId, sizeId) => {
    try {
        const response = await fetch(`${baseUrl}calculator/kit/templates/?fabric_id=${fabricId}&kit_template_size_id=${sizeId}`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return getFabricTemplate(res.access, fabricId, sizeId).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const getKitTemplate = async (token) => {
    try {
        const response = await fetch(`${baseUrl}calculator/templates/`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return getKitTemplate(res.access).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const getKitCustomComponents = async (token, page, filter = null, search = null) => {
    try {
        const response = await fetch(`${baseUrl}calculator/kit/components/additional/?page=${page}${filter ? `&component_type=${filter}` : ''}${search ? `&search=${search}` : ''}`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return getKitCustomComponents(res.access, page, filter = null, search = null).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const setKit = async (token, data) => {
    let handleChange = {
        order_id: data.order_id,
        fabric_id: data.fabric_id,
        template_components: data.template_components,
    }
    if (data?.comment) {
        handleChange = {
            ...handleChange,
            comment: data.comment
        }
    }
    if (data?.additional_components && data?.additional_components.length > 0) {
        handleChange = {
            ...handleChange,
            additional_components: data.additional_components
        }
    }
    if (data?.option && data?.option.length > 0) {
        handleChange = {
            ...handleChange,
            option: data.option
        }
    }
    if (data?.custom_size && data?.custom_size !== '') {
        handleChange = {
            ...handleChange,
            custom_size: data.custom_size
        }
    }

    try {
        const response = await fetch(`${baseUrl}calculator/kit/`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ...handleChange
            })
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return setKit(res.access, data).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const setUpdateKit = async (token, data, id) => {
    let handleChange = {
        component: data.component,
    }
    if (data?.comment) {
        handleChange = {
            ...handleChange,
            comment: data.comment
        }
    }
    if (data?.additional_components && data?.additional_components.length > 0) {
        handleChange = {
            ...handleChange,
            additional_components: data.additional_components
        }
    }
    if (data?.option) {
        handleChange = {
            ...handleChange,
            option: data.option
        }
    }
    if (data?.custom_size && data?.custom_size !== '') {
        handleChange = {
            ...handleChange,
            custom_size: data.custom_size
        }
    }

    console.log(handleChange)

    try {
        const response = await fetch(`${baseUrl}calculator/kit/${id}/`, {
            method: "PATCH",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ...handleChange
            })
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return setUpdateKit(res.access, data, id).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const removeKitComponent = async (token, kitId, id) => {
    try {
        const response = await fetch(`${baseUrl}calculator/kit/components/${id}/?kit_id=${kitId}`, {
            method: "DELETE",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return removeKitComponent(res.access, kitId, id).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const removeProduct = async (token, id) => {
    try {
        const response = await fetch( `${baseUrl}products/order-product/${id}/` , {
            method: "DELETE",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });

        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return removeProduct(res.access, id).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.status;
        }

    } catch (error) {
        console.log(error);
    }
}

export const getReceipt = async (paymentId) => {
    try {
        const response = await fetch( `${baseUrl}receipt/?payment=${paymentId}` , {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        return response.json();
    } catch (error) {
        console.log(error);
    }
}

export const setReceipt = async (formData) => {
    try {
        const response = await fetch( `${baseUrl}receipt/`, {
            method: "POST",
            body: formData
        });
        return response.json();
    } catch (error) {
        console.log(error);
    }
}
