import {useParams} from "react-router";
import React, {useEffect, useState} from "react";
import {getAccessToken, getOrderInfo, updateCustomer, updatePayment} from "../../../api/api";
import OrderCreate from "./OrderCreate";
import Preloader from "../../Common/Preloader/Preloader";
import NotFound from "../../Common/NotFound/NotFound";
import { useAppDispatch } from "../../../hooks/redux";
import { setIsNotFound } from "../../../store/main-slice";


const OrderWrapper = () => {
    const {id} = useParams();
    const [order, setOrder] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useAppDispatch();

    const updateOrder = (value) => {
        getAccessToken().then((token) => {
            setIsLoading(true);
            getOrderInfo(token, value).then((response) => {
                if (response && response.id) {
                    setOrder(response);
                    if (!response?.payment) {
                        updatePayment(token, response.id, {'post_paid_amount': null}).then((res) => console.log(res));
                    }
                    if (!response?.customer) {
                        updateCustomer(token, response.id, {'first_name': null}).then((res) => console.log(res));
                    }
                } else {
                    dispatch(setIsNotFound(true));
                }
            }).finally(() => {
                setIsLoading(false);
            })
        })
    }
    useEffect(() => {
        if (id) {
            updateOrder(id);
        }
    }, [id])


    return (
        <>
            {order && (
                <OrderCreate order={order}/>
            )}
            {isLoading && (
                <Preloader />
            )}
            {!isLoading && !order && (
                <NotFound message={`Замовлення №${id} не знайдено`} />
            )}
        </>
    )
}

export default OrderWrapper;