import React from "react";
import styles from './FabricSet.module.scss';
import FabricSetItem from "./FabricSetItem/FabricSetItem";
import { removeKitComponent, getAccessToken } from "../../../../api/api";

const FabricSet = ({components, setComponents, customComponents, setCustomComponents, kitId, types, form, setForm, isEdit}) => {

    const onChangeOptions = (value, index, type, detail) => {

        let newObj = { ...form };
        let component = newObj.template_components[index];

        if (!component) {
            return;
        }

        if (type === 'option') {
            if (!detail) {
                if (component.option.includes(value)) {
                    component.option = component.option.filter((el) => el !== value);
                } else {
                    component.option.push(value);
                }
                setForm({ ...newObj });
            } else if (detail === 'option_array') {
                component.option = value
            }
        } else if (type === 'comment') {
            component.comment = value;
        } else if (type === 'custom_size') {
            component.custom_size = value;
        }
    }

    const handleDeleteComponent = (idx, itemId) => {
        if (isEdit) {
            getAccessToken().then((token) => {
                removeKitComponent(token, kitId, itemId).then(result => {
                    if (result && result.kit && result.kit.length > 0) {
                        setComponents(prevComponents => ({ ...prevComponents, components: result.kit[0].components }));
                        const newTemplateComponents = form.template_components.filter((item) => item.component_template_id !== itemId);
                        setForm(prevForm => ({ ...prevForm, template_components: newTemplateComponents }));
                    } else {
                        console.log(result);
                    }
                })
            })
        } else {
            const newComponents = components.filter((_, index) => index !== idx);
            setComponents(newComponents);
        }
    }

    const handleDeleteCustomComponent = (idx) => {
        const newCustomComponents = customComponents.filter((_, index) => index !== idx);
        setCustomComponents(newCustomComponents);
    }

    return (
      <div>
          {Array.isArray(components) && components.length > 0 ? (
              components.map((el, index) => (
                <FabricSetItem
                    componentIndex={index}
                    item={el}
                    key={index}
                    kitId={kitId}
                    types={types}
                    onChangeOptions={onChangeOptions}
                    form={form}
                    onDelete={handleDeleteComponent}
                    isEdit={isEdit}
                />
            ))
          ) : (
              <p className={styles.warning}>Очікуємо доступні комплекти</p>
          )}
          {Array.isArray(customComponents) && customComponents.length > 0 && (
            customComponents.map((el, index) => (
                <FabricSetItem
                    componentIndex={index}
                    item={el}
                    key={index}
                    kitId={kitId}
                    types={types}
                    onChangeOptions={onChangeOptions}
                    form={form}
                    onDelete={handleDeleteCustomComponent}
                    isEdit={isEdit}
                />
            ))
          )}
      </div>
    )
}

export default FabricSet