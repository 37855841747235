import React from "react";
import styles from './InvoiceInfo.module.scss';
import SearchInput from "./Input/SearchInput";

const InvoiceInfo = ({order}) => {
    const getOrderStatusName = (orderStatus) => {
        switch (orderStatus) {
            case 'PAID': return 'Оплачено';
            case 'IN_PROG': return 'В обробці';
            case 'PAY_WAIT': return 'Очікує оплати'
            case 'NOT_PAID': return 'Не оплачено';
            default:
                console.log(orderStatus)
                break;
        }
        return orderStatus
    }

    return (
        <div className={styles.invoiceInfo}>
            {order?.payment?.status && order?.payment?.status !== 'NONE' && (
                <div className={styles.invoiceInfo__status}>
                    <p>{getOrderStatusName(order.payment.status)}</p>
                </div>
            )}
            <div className={styles.invoiceInfo__search}>
                <SearchInput order={order} />
            </div>
        </div>
    )
}

export default InvoiceInfo;