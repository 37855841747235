import React, {useEffect, useState} from "react";
import styles from './Fabrics.module.scss';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getFabricsAll} from "../../../store/selectors";
import FabricChooser from "./FabricChooser/FabricChooserList";
import FabricsContent from "./FabricsContent/FabricsContent";
import {getAccessToken, getFabrics} from "../../../api/api";
import {setFabricsList} from "../../../store/main-slice";
import { Pagination } from '@mui/material';
import SearchFabrics from "../../Common/SearchFabrics/SearchFabrics";
import Preloader from "../../Common/Preloader/Preloader";

const Fabrics = () => {
    const fabricsAll = useAppSelector(getFabricsAll);
    const dispatch = useAppDispatch();
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [fabricFilters, setFabricFilters] = useState([])
    const [selectedFabric, setSelectedFabric] = useState([]);
    const [filter, setFilter] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (fabricsAll) {
            getAccessToken().then((token) => {
                setIsLoading(true);
                getFabrics(token, 1).then((response) => {
                    if (response?.fabrics && response.fabrics.length > 0) {
                        dispatch(setFabricsList(response.fabrics));
                    }
                    if (response?.total_pages) {
                        setTotalPages(response.total_pages)
                    }
                    if (response?.fabric_types) {
                        setFabricFilters(response.fabric_types);
                    }
                    setIsLoading(false);
                })
            }).catch(err => {
                console.error("Error fetching fabrics: ", err);
                setIsLoading(false);
            });
        }
    }, []);

    const updateFabrics = (response) => {
        if (response?.fabrics) {
            dispatch(setFabricsList(response.fabrics));
            setIsLoading(false);
        }
        if (response?.total_pages) {
            setTotalPages(response.total_pages)
        }
    }

    const handlePaginationChange = (event, value) => {
        if (page !== value) {
            setPage(value);
            window.scrollTo({top: 0, behavior: 'auto'});
            getAccessToken().then((token) => {
                setIsLoading(true);
                if (filter && filter.length > 0 && searchValue?.length > 0) {
                    getFabrics(token, value, filter, searchValue).then((response) => {
                        updateFabrics(response);
                    }).catch(err => {
                        console.error("Error fetching fabrics on page change: ", err);
                        setIsLoading(false);
                    })
                } else if (filter && filter.length === 0 && searchValue?.length > 0) {
                    getFabrics(token, value, null, searchValue).then((response) => {
                        updateFabrics(response);
                    }).catch(err => {
                        console.error("Error fetching fabrics on page change: ", err);
                        setIsLoading(false);
                    })
                } else if (filter && filter.length > 0 && searchValue?.length === 0) {
                    getFabrics(token, value, filter).then((response) => {
                        updateFabrics(response);
                    }).catch(err => {
                        console.error("Error fetching fabrics on page change: ", err);
                        setIsLoading(false);
                    })
                } else {
                    getFabrics(token, value).then((response) => {
                        updateFabrics(response);
                    }).catch(err => {
                        console.error("Error fetching fabrics on page change: ", err);
                        setIsLoading(false);
                    })
                }
            })
        }
    };

    const onSendFilters = (filterArray) => {
        setTimeout(() => {
            getAccessToken().then((token) => {
                setIsLoading(true);
                if (!searchValue.length) {
                    getFabrics(token, 1, filterArray).then((response) => {
                        updateFabrics(response);
                    }).catch(err => {
                        console.error("Error fetching fabrics on page change: ", err);
                        setIsLoading(false);
                    })
                } else {
                    getFabrics(token, 1, filterArray, searchValue).then((response) => {
                        updateFabrics(response);
                    }).catch(err => {
                        console.error("Error fetching fabrics on page change: ", err);
                        setIsLoading(false);
                    })
                }
            })
        },)
    }

    const handleChange = (value) => {
        if (selectedFabric.length) {
            dispatch(setFabricsList([]));
            const isItemValue = selectedFabric.find((item) => item === value);
            if (isItemValue) {
                const newArray = selectedFabric.filter((item) => item !== value);
                const newFilterArray = filter.filter((item) => item !== value.id);
                setSelectedFabric([...newArray]);
                setFilter([...newFilterArray]);
                onSendFilters([...newFilterArray])

            } else {
                setSelectedFabric([...selectedFabric, value]);
                setFilter([...filter, value.id]);
                onSendFilters([...filter, value.id])
            }
        } else {
            const newArray = [value];
            setSelectedFabric(newArray)
            setFilter([value.id])
            onSendFilters([value.id])
        }
    }

    return (
        <div className={styles.fabrics}>
            <SearchFabrics searchValue={searchValue} setSearchValue={setSearchValue} setPage={setPage} setTotalPages={setTotalPages}
                           page={page} filter={filter} setIsLoading={setIsLoading}/>
            {fabricFilters && fabricFilters.length > 0 && (
                <FabricChooser handleChange={handleChange} fabricFilters={fabricFilters} selectedFabric={selectedFabric}
                               setSelectedFabric={setSelectedFabric}
                />
            )}
            {Array.isArray(fabricsAll) && fabricsAll.length > 0 ? (
                <FabricsContent fabricsAll={fabricsAll} totalPages={totalPages}/>
            ) : (
                <p className={styles.warning}>Очікуємо доступні тканини</p>
            )}
            <Pagination count={totalPages} page={page} siblingCount={1} boundaryCount={1} hidePrevButton hideNextButton onChange={handlePaginationChange} />
            {isLoading && (
                <Preloader />
            )}
        </div>
    )
}

export default Fabrics;