import React from "react";
import styles from './FabricSlider.module.scss';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import copyIcon from "../../assets/icons/copy4.svg";
import { useCopyImage } from "../../hooks/useCopyImage";

const FabricSlider = ({images}) => {
    const { isImageCopying, copyImageToClipboard } = useCopyImage();

    const settings = {
        customPaging: function(i) {
            return (
                <a>
                    <img src={images[i]} alt=""/>
                </a>
            );
        },
        dots: true,
        arrows: false,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <div className={styles.fabricSlider}>
            <Slider {...settings}>
                {
                    images.map((img, index) => (
                        <div className={styles.sliderItem} key={index}>
                            <img className={styles.sliderImg} src={img} alt=""/>
                            <button
                                className={`${styles.copyButton} click-effect`}
                                style={isImageCopying ? {pointerEvents: 'none'} : null}
                                onClick={() => copyImageToClipboard(img)}
                            >
                                <img src={copyIcon} alt=""/>
                            </button>
                        </div>
                    ))
                }
            </Slider>
        </div>
    )
}

export default FabricSlider;