import React, {useEffect, useMemo, useState} from "react";
import styles from './OrderStatus.module.scss';
import OrderInfoBlock from "../../Common/OrderInfoBlock/OrderInfoBlock";
import ProductItem from "../../Common/ProductItem/ProductItem";
import OrderActions from "./OrderActions";
import {getAccessToken, getCartOrder, getDeliverNP, getOrderInfo, getReceipt, updatePayment} from "../../../api/api";
import {useAppDispatch} from "../../../hooks/redux";
import PriceInfo from "../../ClientPage/Status/Price/Price";
import Preloader from "../../Common/Preloader/Preloader";
import { useParams } from "react-router-dom";
import NotFound from "../../Common/NotFound/NotFound";
import { setIsNotFound } from "../../../store/main-slice";

const OrderStatus = () => {
    const {id} = useParams();
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [order, setOrder] = useState(null);
    const [warehouseItems, setWarehouseItems] = useState([]);
    const [deliveryInfo, setDeliveryInfo] = useState(null);
    const [receipt, setReceiptUrl] = useState(null);

    const getOrderStatusName = (orderStatus) => {
        switch (orderStatus) {
            case 'PAID': return 'Оплачено';
            case 'IN_PROG': return 'В обробці';
            case 'PAY_WAIT': return 'Очікує оплати'
            case 'NOT_PAID': return 'Не оплачено';
            default:
                console.log(orderStatus)
                break;
        }
        return orderStatus
    }

    const totalPrice = useMemo(() => {
        if (order?.id && warehouseItems.length > 0) {
            const result = warehouseItems.reduce((sum, {warehouse_item, kit}) => sum + (warehouse_item ? warehouse_item.price : kit.price_retail), 0);
            getAccessToken().then((token) => {
                updatePayment(token, order.id, {'amount': result}).then((res) => console.log(res))
            });
            return result;
        }
    }, [order?.id, warehouseItems])

    const handleSaveFinalPrice = (finalPrice) => {
        getAccessToken().then((token) => {
            updatePayment(token, order.id, {'final_amount': finalPrice}).then((res) => console.log(res))
        });
    }

    useEffect(() => {
        getAccessToken().then((token) => {
            setIsLoading(true);
            getOrderInfo(token, id).then((response) => {
                if (response && response.id) {
                    setOrder(response);
                    setIsLoading(true);
                    getCartOrder(token, id).then((response) => {
                        if (response && response.length > 0) {
                            setWarehouseItems(response);
                        }
                    }).finally(() => {
                        setIsLoading(false);
                    })
                    if (response?.payment?.id) {
                        getReceipt(response.payment.id).then(res => {
                            if (res?.length > 0 && res[res.length - 1]) {
                                setReceiptUrl(res[res.length - 1]);
                            }
                        })
                    }
                } else {
                    setIsLoading(false);
                    dispatch(setIsNotFound(true));
                }
            }).catch(err => {
                console.log(err);
                setIsLoading(false);
            })
            getDeliverNP(token, id).then((result) => {
                if (result && result.length > 0) {
                    setDeliveryInfo(result[0])
                }
            })
        });
    }, [])

    return (
        <>
            {!isLoading && order && (
                <div className={styles.orderStatus}>
                    {order && order.id && (
                        <h2>Замовлення № {order.id}</h2>
                    )}
                    <OrderInfoBlock url={order?.customer?.url} departmentNumber={deliveryInfo?.receiver?.department_number} ttn={deliveryInfo?.delivery?.ttn}/>
                    {order?.payment?.status && order?.payment?.status !== 'NONE' && (
                        <div className={styles.statusBar}>
                            <span>{getOrderStatusName(order.payment.status)}</span>
                        </div>
                    )}
                    {warehouseItems && warehouseItems.length > 0 && warehouseItems.map((el, index) => (
                        <ProductItem item={el} key={index} isDisabled={order?.payment?.status === 'PAID' || order?.payment?.status === 'PAY_WAIT'}/>
                    ))}
                        
                    <OrderActions orderUrl={order?.url} isDisabled={order?.payment?.status === 'PAY_WAIT'} receiptUrl={receipt?.receipt ?? ''} />
                    <PriceInfo order={order} totalPrice={totalPrice} setFinalPrice={handleSaveFinalPrice}/>
                    <br/>
                    <br/>
                    {isLoading && (
                        <Preloader />
                    )}
                </div>
            )}
            {isLoading && <Preloader />}
            {!isLoading && !order && (
                <NotFound message={`Замовлення №${id} не знайдено`} />
            )}
        </>
    )
}

export default OrderStatus;