import React, {useEffect, useState} from "react";
import styles from './Callback.module.scss';
import logo from "../../../assets/img/logoEgo.svg";
import CallbackForm from "./CallbackForm/CallbackForm";
import CallbackWorkTime from "./CallbackWorkTime";
import {getWorkSchedule} from "../../../api/api";
import Preloader from "../../Common/Preloader/Preloader";

const Callback = () => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (data === null) {
            setIsLoading(true);
            getWorkSchedule().then((res) => {
                if (res && res[0].weekend) {
                    setData(res[0]);
                }
            }).finally(() => {
                setIsLoading(false);
            })
        }
    }, [])

    return (
        <>
            {isLoading && <Preloader />}
            <div className={styles.callback}>
                <div className={styles.callback__logo}>
                    <img src={logo} alt=""/>
                </div>
                <h2>Замовити дзвінок</h2>
                <CallbackForm/>
                <h2>Години роботи менеджерів</h2>
                {data && (
                    <CallbackWorkTime workingDay={data.working_day} workingStartTime={data.working_hours_start}
                                    workingEndTime={data.working_hours_end} weekend={data.weekend}/>
                )}

                <p className={styles.callback__text}>
                    Вона популяризувалась в 60-их роках минулого сторіччя завдяки виданню зразків шрифтів Letraset, які
                    містили уривки з Lorem Ipsum, і вдруге - нещодавно завдяки програмам комп'ютерного верстування на кшталт
                    Aldus Pagemaker, які використовували різні версії Lorem Ipsum.
                </p>
            </div>
        </>
    )
}

export default Callback;