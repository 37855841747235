import React, {useEffect} from "react";
import searchIcon from "../../../assets/icons/search.svg";
import {getAccessToken, getFabrics} from "../../../api/api";
import {setFabricsList} from "../../../store/main-slice";
import {useAppDispatch} from "../../../hooks/redux";
import styles from './SearchFabrics.module.scss';


const SearchFabrics = ({
    searchValue,
    setSearchValue,
    setPage,
    setTotalPages,
    filter,
    isCalculator = false,
    setList = null,
    setIsLoading
}) => {
    const dispatch = useAppDispatch();
    let timeout;

    const sendSearch = (page, filter, search) => {
        setTimeout(() => {
            getAccessToken().then((token) => {
                setIsLoading(true);
                getFabrics(token, page, filter, search).then((response) => {
                    if (response?.fabrics) {
                        isCalculator ? setList(response.fabrics) : dispatch(setFabricsList(response.fabrics));
                    }
                    if (response?.total_pages) {
                        setTotalPages(response.total_pages)
                    }
                }).catch(err => {
                    console.error("Error fetching fabrics on page change: ", err);
                }).finally(() => {
                    setIsLoading(false);
                })
            })
        }, )
    }

    useEffect(() => {
        if (searchValue.length > 0) {
            clearTimeout(timeout);

            timeout = setTimeout(() => {
                sendSearch(1, filter, searchValue);
            }, 500);
            return () => {
                clearTimeout(timeout);
            };
        } else if (searchValue === '') {
            setPage(1);
            sendSearch(1, filter);
        }
    }, [searchValue]);

    return (
        <div className={styles.fabrics__search}>
            <input type="search" placeholder={'Пошук'} className={'baseInput'} value={searchValue}
                   onChange={(e) => setSearchValue(e.target.value)}/>
            <img src={searchIcon} alt=""/>
        </div>
    )
}

export default SearchFabrics;