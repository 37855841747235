import React, {useState} from "react";
import styles from './Order.module.scss';
import items from '../../../../assets/icons/itemsIcon.svg'
import menu from '../../../../assets/icons/menuDots.svg'
import {useNavigate} from "react-router";
import OrderMenu from "./OrderMenu";
import CancelOrder from "./Popup/CancelOrder";
import {useAppDispatch} from "../../../../hooks/redux";
import {removeStateOrder} from "../../../../store/main-slice";
import {getAccessToken, removeOrder} from "../../../../api/api";
import closeIcon from "../../../../assets/icons/closeCross.svg";


const Order = ({item}) => {
    const navigate = useNavigate();
    const [showMenu, setShowMenu] = useState(false);
    const [isShowPopup, setIsShowPopup] = useState(false)
    const dispatch = useAppDispatch();

    const onEditOrder = (id) => {
        navigate(`/order/${id}`);
    }

    const openPopup = () => {
        setShowMenu(false);
        setIsShowPopup(true);
    }

    const closePopup = () => {
        setIsShowPopup(false);
    }

    const deleteOrder = () => {
        getAccessToken().then((token) => {
            setShowMenu(false);
            setIsShowPopup(false);
            removeOrder(token, item.id).then((result) => {
                if (result && result === 204) {
                    dispatch(removeStateOrder(item.id));
                }
            })
        })
    }

    const getFormattedDate = (dateString) => {
        const date = new Date(dateString);
        if (!date) return;
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    }

    return (
        <div className={styles.order} onClick={() => {
            onEditOrder(item.id)
        }}>
            <p className={styles.order__header}>
                <span>#{item.id}</span>
                <span>{getFormattedDate(item.created)}</span>
            </p>
            <div className={styles.content}>
                <div className={styles.content__boxes}>
                    <div className={styles.box}>
                        <span className={styles.box__role}>Замовник</span>
                        {item && item.customer && (
                            <p className={styles.box__text}>{item.customer.username}</p>
                        )}
                        {item && item.customer && (
                            <p className={styles.box__text}>{item.customer.last_name} {item.customer.first_name} {item.customer.middle_name}</p>
                        )}
                        {item && item.customer && item.customer.phone && (
                            <p className={styles.box__phone}>{item.customer.phone}</p>
                        )}
                    </div>
                    <div className={styles.box}>
                        <span className={styles.box__role}>Менеджер</span>
                        <p className={styles.box__text}>{item.managerNickname}</p>
                        {item && item.manager && (
                            <p className={styles.box__text}>{item.manager.username}</p>
                        )}
                        {item && item.manager && (
                            <p className={styles.box__text}>{item.manager.last_name} {item.manager.first_name} {item.manager.middle_name}</p>
                        )}

                    </div>
                </div>
                <div className={styles.content__count}>
                    <img src={items} alt=""/>
                    <p>{item.warehouse_item_count ? item.warehouse_item_count : 0} товарів</p>
                    <p>{item.kit_count ? item.kit_count : 0} комплектів</p>
                </div>
            </div>
            <div className={styles.order__footerWrapper}>
                <div className={styles.order__footer}>
                    <span>{item?.payment?.final_amount ? item.payment.final_amount : item?.payment?.amount ? item.payment.amount : 0} грн</span>
                    <button className={styles.menuBtn} onClick={(e) => {
                        e.stopPropagation()
                        setShowMenu(true);
                    }}>
                        <img src={menu} alt=""/>
                    </button>
                </div>
            </div>
            {showMenu && (
                <button className={styles.backgroundClose} onClick={() => setShowMenu(false)}/>
            )}
            {showMenu && (
                <OrderMenu setShowMenu={setShowMenu} onEditOrder={onEditOrder} id={item.id} openPopup={openPopup} isFavorite={item.is_favourite}/>
            )}
            {isShowPopup && (
                <span className={isShowPopup ? styles.shadowActive : styles.shadowhide}/>
            )}
            {isShowPopup && (
                <div className={isShowPopup ? styles.globalPopupActive : styles.globalPopup}
                     onClick={(e) => e.stopPropagation()}>
                    <div className={styles.header}>
                        <p className={styles.header__title}>Видалення замовлення</p>
                        <div className={styles.header__close} onClick={() => closePopup()}>
                            <span>Закрити</span>
                            <button>
                                <img src={closeIcon} alt=""/>
                            </button>
                        </div>
                    </div>
                    <div className={styles.globalPopup__content}>
                        <CancelOrder onCancel={closePopup} onConfirm={deleteOrder}/>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Order;