import React, {useEffect, useState} from "react";
import styles from "./UkrPoshtaRegion.module.scss";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {getAccessToken, getCities} from "../../../../api/api";
import '../DeliveryUKR.scss';


const UkrPoshtaRegion = ({region, setRegion}) => {
    const [regions, setRegions] = useState([]);
    const [value, setValue] = useState('');

    let timeout;

    useEffect(() => {
        if (value.length > 2) {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                getAccessToken().then((token) => {
                    getCities(token, value).then((response) => {
                        if (response && response.cities) {
                            const changerArray = response.cities.map((item) => {
                                return (
                                    {label: item.city, id: item.city_ref, area: item.area, settlement_type: item.settlement_type}
                                )
                            })
                            setRegions(changerArray)
                        }
                    })
                })
            }, 500);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [value]);


    return (
        <div className={styles.ukrPoshtaRegion}>
            <span className="coloredLabel colored--region"></span>
            <Autocomplete
                disablePortal
                sx={{
                    "& .MuiInputLabel-root": { fontSize: '12px !important' }
                }}
                ListboxProps={{
                    sx: {
                        fontSize: '12px'
                    }
                }}
                options={regions}
                value={region}
                noOptionsText="No regions"
                onKeyUp={(e) => setValue(e.target.value)}
                onChange={(event, newValue) => setRegion(newValue)}
                renderInput={(params) => <TextField {...params} label="Область або регіон" />}
            />
        </div>
    )
}

export default UkrPoshtaRegion;