import React, {useEffect, useState} from "react";
import styles from './Main.module.scss';
import UserHeader from "./UserHeader/UserHeader";
import MainTabs from "./MainTabs/MainTabs";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getOrderList, getUser} from "../../../store/selectors";
import Order from "./Order/Order";
import {getAccessToken, getOrders, getOrdersRecentViews} from "../../../api/api";
import {setOrders} from "../../../store/main-slice";
import OrderSearchFilters from "./OrderSearchFilters/OrderSearchFilters";
import {Pagination} from "@mui/material";
import Preloader from "../../Common/Preloader/Preloader";

const Main = () => {
    const [isLoading, setIsLoading] = useState(false);
    const orderList = useAppSelector(getOrderList);
    const {id} = useAppSelector(getUser);
    const [ordersFilter, setOrdersFilter] = useState('my');
    const [searchValue, setSearchValue] = useState('');
    const [filterLabels, setFilterLabels] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(2);
    const dispatch = useAppDispatch();

    useEffect(() => {
        getAccessToken().then((token) => {
            setIsLoading(true);
            getOrders(token, 1, id).then(result => {
                if (result?.orders && result.orders.length > 0) {
                    dispatch(setOrders(result.orders));
                    setTotalPages(result.total_pages);
                    setIsLoading(false);
                }
            });
        })

    }, [id])

    const onResetSearch = () => {
        setSearchValue('');
        setFilterLabels(null)
    }

    const onChangeList = (filter, pageValue) => {
        if (filter) {
            setIsLoading(true);
            getAccessToken().then((token) => {
                if (filter === 'all') {
                    getOrders(token, pageValue).then(result => {
                        if (result?.orders && result.orders.length > 0) {
                            dispatch(setOrders(result.orders));
                            setTotalPages(result.total_pages);
                            setIsLoading(false);
                        }
                    });
                    onResetSearch()
                }
                if (filter === 'my') {
                    getOrders(token, pageValue, id).then(result => {
                        if (result?.orders && result.orders.length > 0) {
                            dispatch(setOrders(result.orders));
                            setTotalPages(result.total_pages);
                            setIsLoading(false);
                        }
                    });
                    onResetSearch()
                }
                if (filter === 'favorite') {
                    getOrders(token, pageValue, id, true).then(result => {
                        if (result?.orders && result.orders.length > 0) {
                            dispatch(setOrders(result.orders));
                            setTotalPages(result.total_pages);
                            setIsLoading(false);
                        }
                    });
                    onResetSearch()
                }
                if (filter === 'history') {
                    getOrdersRecentViews(token, id).then(result => {
                        if (result?.length > 0) {
                            dispatch(setOrders(result));
                            setTotalPages(1);
                            setIsLoading(false);
                        }
                    });
                    onResetSearch()
                }
            })
        }
    }

    const handlePaginationChange = (event, value) => {
        if (page !== value) {
            setPage(value);
            window.scrollTo({top: 0, behavior: 'auto'});
            if (searchValue === '') {
                onChangeList(ordersFilter, value)
            }
        }
    }

    const handleSearchText = (text) => {
        if (/[^a-zа-яґєії0-9+\-'_. ]+/ig.test(text) && text !== '') {
            return;
        }
        setSearchValue(text);
    }

    return (
        <div className={styles.main}>
            <UserHeader/>
            <OrderSearchFilters setOrdersFilter={setOrdersFilter} searchValue={searchValue}
                                setSearchValue={handleSearchText} filterLabels={filterLabels}
                                setFilterLabels={setFilterLabels} setPage={setPage} setTotalPages={setTotalPages}
                                page={page}/>
            <MainTabs orders={ordersFilter} setOrders={setOrdersFilter} onChangeList={onChangeList} setPage={setPage}/>
            {orderList && orderList.length > 0 && (
                <div className={styles.orderWrapper}>
                    {orderList.map((item, index) => (
                        <Order key={index} item={item}/>
                    ))}
                </div>
            )}
            {totalPages > 1 && (
                <Pagination className={styles.mainPagination} count={totalPages} page={page} siblingCount={1}
                            boundaryCount={1} hidePrevButton hideNextButton onChange={handlePaginationChange}/>
            )}
            {isLoading && (
                <Preloader />
            )}
        </div>
    )
}

export default Main;