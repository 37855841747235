import React from "react";
import styles from './Menu.module.scss';
import {Link} from 'react-router-dom';
import statusIcon from '../../assets/icons/status.svg';
import contactsIcon from '../../assets/icons/contacts.svg';
import contactsIconBrown from '../../assets/icons/contactsBrown.svg';
import callbackIcon from '../../assets/icons/callback.svg';
import callbackIconBrown from '../../assets/icons/callbackBrown.svg';
import authIcon from '../../assets/icons/auth.svg';
import authIconBrown from '../../assets/icons/authBrown.svg';
import {useLocation} from "react-router";

const UserMenu = () => {
    const {pathname} = useLocation();

    return (
        <div className={`menu ${styles.userMenu}`}>
            <Link to={'/orders/'} className={pathname === '/orders/' ? styles.userMenu__linkActive : styles.userMenu__link}>
                <img src={statusIcon} alt=""/>
                <span>Статус</span>
            </Link>
            <Link to={'/contacts/'} className={pathname === '/contacts/' ? styles.userMenu__linkActive : styles.userMenu__link}>
                <img src={pathname === '/contacts/' ? contactsIconBrown : contactsIcon} alt=""/>
                <span>Контакти</span>
            </Link>
            <Link to={'/callback/'}  className={pathname === '/callback/' ? styles.userMenu__linkActive : styles.userMenu__link}>
                <img src={pathname === '/callback/' ? callbackIconBrown : callbackIcon} alt=""/>
                <span>Замовити дзвінок</span>
            </Link>
            <Link to={'/'}  className={pathname === '/' ? styles.userMenu__linkActive : styles.userMenu__link}>
                <img src={pathname === '/' ? authIconBrown : authIcon} alt=""/>
                <span>Службовий вхід</span>
            </Link>
        </div>
    )
}

export default UserMenu;