import React, {useEffect, useState} from "react";
import styles from './Contacts.module.scss';
import logo from "../../../assets/img/logoEgo.svg";
import ContactsContent from "./ContactsContent/ContactsContent";
import ContactsAbout from "./ContactsAbout";
import {getCompanyInfo, getWorkSchedule} from "../../../api/api";
import Preloader from "../../Common/Preloader/Preloader";

const Contacts = () => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (data === null) {
            setIsLoading(true);
            getCompanyInfo().then((res) => {
                if (res && res[0].id) {
                    setData(res[0]);
                }
            }).finally(() => {
                setIsLoading(false);
            })
        }
    }, [])

    return (
        <>
            <div className={styles.contacts}>
                <div className={styles.contacts__logo}>
                    <img src={logo} alt=""/>
                </div>
                <h2>Контакти</h2>
                {data && (
                    <ContactsContent phone={data.phone} email={data.email} site={data.site}/>
                )}
                <ContactsAbout/>
            </div>
            {isLoading && <Preloader />}
        </>
    )
}

export default Contacts;