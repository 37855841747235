import React from "react";
import styles from './Price.module.scss'
import TotalPrice from "../../../AuthorizedPage/Products/TotalPrice/TotalPrice";

const PriceInfo = ({order, totalPrice, setFinalPrice}) => {
    const price = totalPrice || order?.payment?.amount;

    return (
        <div className={styles.price}>
            <TotalPrice
                price={price}
                discountType={order?.payment?.discount_type}
                discountValue={order?.payment?.discount_amount}
                setFinalPrice={setFinalPrice}
            />
            {order?.payment?.discount_amount > 0 && (
                <div className={styles.discount}>
                    <>
                        <p>Знижка: {order.payment.discount_type === 'PERC' && <span className={styles.discount__percent}>{order.payment.discount_amount}%</span>}</p>
                        <span className={styles.discount__number}>
                            -{order.payment.discount_type === 'VAL'
                                ? order.payment.discount_amount
                                : Math.floor((price * order.payment.discount_amount) / 100)} грн
                        </span>
                    </>
                </div>
            )}
        </div>
    )
}

export default PriceInfo;