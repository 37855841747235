import React from "react";
import BtnGroup from "./Button1/Button";
import Btn from "./Button2/Button";
import IbanBtn from "./Button3/Button";

const Payment = ({orderId, paymentId, isDisabled = false}) => {
    return (
        <div>
            <h3>Оплатити онлайн</h3>
            {paymentId && <BtnGroup paymentId={paymentId} isDisabled={isDisabled} />}
            {orderId && <IbanBtn orderId={orderId} />}
            {paymentId && <Btn paymentId={paymentId} isDisabled={isDisabled} />}
        </div>
    )
}

export default Payment;